<template>
  <div>
    <NuxtLayout name="default">
      <Widgets v-if="page?.widgets" :widgets="page?.widgets" />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({ layout: false })

const { data: page } = await useAsyncData('page', () =>
  useApi().publicPagesSimplePagesRetrieve('home')
)

const { meta } = usePrepareSEOHead(page)
useHead({
  title: page.value?.title,
  meta
})

const headerColor = useHeaderColor()
headerColor.value = 'brown'

const { links } = useBreadcrumbs()
links.value = []
</script>
